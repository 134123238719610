import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import './SIngleNews.css'
import allEndpoints from "../shared/http";
import {Box, Breadcrumbs, Container, Divider, Link, Skeleton, Stack, Typography} from "@mui/material";
import PageTitle from "../shared/layout/PageTitle";
import palette from "../shared/theme/palette";
import useMediaQuery from "@mui/material/useMediaQuery";
import DocumentList from "../widgets/DocumentList";
import {formatDate} from "../shared/helper/date";

const SingleNews = () => {
    const id = useParams().id;
    const isMobile = useMediaQuery('(max-width:600px)');

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [post, setPost] = useState([]);
    const handleGetPost = async () => {
        setIsLoading(true);
        try {
            const response = await allEndpoints.news.getPost(id)
            // Проверка HTTP-статуса ответа
            if (response.status === 200) {
                setPost(response.data.result)
            } else {
                console.error('Ошибка при получении тарифов:', response.statusText);
                setIsError(true)
            }
        } catch (error) {
            // Обработка ошибки сети
            console.error('Ошибка сети при получении тарифов:', error.message);
            setIsError(true)
        }
        setIsLoading(false);
    };

    useEffect(() => {
        handleGetPost()
    }, [])
    const stringDate = formatDate(post.date)
    const imagePath = post.images && post.images[0]
    const mainImage = `https://www.969975-cv27771.tmweb.ru:${imagePath?.path}`

    return (
        <>
            <Container sx={{flex: 1}}>
                <Stack pt={4} pb={5}>
                    <Breadcrumbs separator={<span style={{color: "#66B3EC"}}>/</span>} aria-label="breadcrumb">
                        {breadcrumbs}
                    </Breadcrumbs>
                    <Stack pt={3} direction={'row'} justifyContent={'space-between'} alignItems={'flex-end'}>
                        <Typography
                            variant={'h1'}
                            className={'news__container'}
                        >
                            {post.title}
                        </Typography>
                        <Typography
                            variant={'body2'}
                        >
                            {stringDate}
                        </Typography>
                    </Stack>
                </Stack>

                <Divider
                    sx={{width: "100%", borderTop: '1px solid', borderColor: palette.grey['200']}}
                />
                {
                    imagePath &&
                    <Stack
                        spacing={2}
                        className={'news__container'}
                        pt={4}
                    >
                        <Box
                            maxHeight={isMobile ? '320px' : '590px'}
                            height={'100vh'}
                            // style={{backgroundColor: palette.grey['200']}}
                            sx={{
                                background:`no-repeat url(${mainImage})`,
                                backgroundPosition: 'left',
                                backgroundSize: 'contain'
                        }}
                        >
                            {/*<img*/}
                            {/*    src={mainImage}*/}
                            {/*    style={{height: '100%', width: '100%', objectFit: 'cover'}}*/}
                            {/*    alt={'Картинка новости'}*/}
                            {/*/>*/}
                        </Box>
                        <Typography variant={'secondaryMedium'} color={palette.grey["400"]}>
                            {post.image_description}
                        </Typography>
                    </Stack>
                }

                <div className={'body__post__html'} dangerouslySetInnerHTML={{__html: post.body}}/>

                <Stack py={6}>
                    {
                        post.documents &&
                        <DocumentList
                            documents={post.documents}
                        />
                    }
                </Stack>

            </Container>
        </>
    );
};


const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
        Главная
    </Link>,
    <Link
        underline="hover"
        key="2"
        color="inherit"
        href="/news"

    >
        Новости
    </Link>,
    <Link
        underline="hover"
        key="2"
        color="text.primary"
        href="/news"
    >

    </Link>
];
export default SingleNews;