import React, {useEffect, useState} from 'react';
import {fetchNewsData} from "../api/newsService";
import NewsListItems from "../../../entities/news/NewsListItems";
import {CircularProgress, Divider} from "@mui/material";
import PostRow from "../../../entities/news/PostRow";
import NewsLayout from "../../../entities/news/NewsLayout";

const NewsList = () => {
    const [news, setNews] = useState([]);
    const [year, setYear] = useState(new Date().getFullYear());
    const [isLoading, setIsLoading] = useState(true);
    const [listYears, setListYears] = useState([2023, 2024, 2025]);
    const fetchData = async () => {
        setIsLoading(true)
        try {
            const newsData = await fetchNewsData(year, 1, 'desc');
            const array = newsData['result']
            setNews(array);
            setIsLoading(false);
            if(array.length > 0){
                // const uniqueYears = new Set(array.map(item => new Date(item.date).getFullYear()));
                // const listYearsArr = Array.from(uniqueYears);
                // setListYears(listYearsArr)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    };
    const toggleYear = (yearPar) => {
        setYear(yearPar)
    }
    useEffect(() => {
        fetchData();
    }, [year]);

    if (isLoading){
        return(
            <NewsLayout>
                <CircularProgress/>
            </NewsLayout>
        )
    }
    return (
        <NewsLayout
            filterYear={listYears}
            selectYear={year}
            onClick={toggleYear}
        >
            {news.map((post, index) =>{
                return(
                    <PostRow
                        key={post.id}
                        title={post.title}
                        date={post.date}
                        id={post.id}
                        subtitle={post.subtitle}
                    />
                )
            })}
        </NewsLayout>
    );
};

export default NewsList;