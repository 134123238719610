import React, {useEffect, useState} from 'react';
import SvgContact from "../shared/svg/SvgContact";
import {Button, Container, Link, Paper, Stack, TextField, Typography} from "@mui/material";
import PageTitle from "../shared/layout/PageTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FaqImg from "../shared/svg/FAQImg";
import ContactForm from "../widgets/feedback/ContactForm";
import useMediaQuery from "@mui/material/useMediaQuery";


const Contact = () => {
    const isMobile = useMediaQuery('(max-width:600px)');
    return (
        <Container>
            <PageTitle
                breadcrumbs={breadcrumbs}
                title={'Контакты'}
                subtitle={'Ознакомьтесь с нашими тарифами и выберите оптимальное решение для себя. Выберите тариф и оставьте\n' +
                '                    заявку на подключение.'}
            />
            <Stack direction={isMobile ? 'column' : "row"} pb={10}>
                <Paper variant={'blueCard'} sx={{maxWidth: isMobile ? '100%' : '35%', width: "100%"}}>
                    <Stack p={4} justifyContent={"space-between"} height={"100%"} minHeight={isMobile && '360px'}>
                        <Typography variant={'h2'}>НАШ ОФИС</Typography>
                        <Stack spacing={3}>
                            <Stack spacing={1}>
                                <Typography variant={'primaryBold'} color={'#ffff'}>г. Куйбышев</Typography>
                                <Typography variant={'secondaryMedium'}>ул. Карла Либкнехта, 1 ОЦ «Альянс»</Typography>
                            </Stack>
                            <Stack spacing={1} color={"rgba(255, 255, 255, 0.80)"}>
                                <Typography variant={'secondaryMedium'}>Режим работы: понедельник-пятница с 09:00 до
                                    18:00.</Typography>
                                <Typography variant={'secondaryMedium'}>Перерыв на обед с 13:00 до 14:00.</Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Paper>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1131.5242713046023!2d78.3138016583381!3d55.44438551648338!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNTXCsDI2JzM5LjgiTiA3OMKwMTgnNTMuNiJF!5e0!3m2!1sru!2sfi!4v1581264752887!5m2!1sru!2sfi"
                    width="100%"
                    height="500"
                    style={{border: 'none'}}
                />
            </Stack>
            <ContactForm/>
        </Container>
    );
};

export default Contact;


const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
        Главная
    </Link>,
    <Link
        underline="hover"
        key="2"
        color="text.primary"
        href="/contact"

    >
        Контакты
    </Link>
];