import React, {useContext, useEffect, useReducer} from 'react';
import {Divider, Stack, Typography} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Link} from "react-router-dom";
import {formatDate} from "../../shared/helper/date";
import DeleteContent from "../../features/deleteContent/UI/DeleteContent";
import {Context} from "../../index";
import Box from "@mui/material/Box";

const PostRow = ({title, subtitle, date, id}) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const {store} = useContext(Context)
    return (
            <Stack>
                {store.isAdmin &&
                    <Box sx={{position: 'absolute', zIndex: '999'}}>
                        <DeleteContent content={'post'} id={id}/>
                    </Box>
                }
                <Link to={`/post/${id}`}>
                <Divider color={"#CCC"} sx={{height: 2}}/>

                <Stack
                    direction={isMobile ? 'column' : 'row'}
                    spacing={isMobile && 3} p={isMobile ? 2 : 5} justifyContent={"space-between"} color={"#333"}>
                    <Typography variant={"h2"}>{formatDate(date)}</Typography>
                    <Stack maxWidth={isMobile ? "100%" : "70%"} width={'100%'} gap={2}>

                        <Typography variant={"h2"}>{title}</Typography>

                        <Typography
                            noWrap={2}
                            sx={{
                                WebkitLineClamp: "2",
                                WebkitBoxOrient: "vertical",
                            }}
                            variant={"primaryMedium"}
                            color={'rgba(0, 0, 0, 0.60)'}
                        >
                            {subtitle}
                        </Typography>
                    </Stack>
                </Stack>
                </Link>
            </Stack>
    );
};

export default PostRow;

//
// const initialState = {
//     title: '',
//     subtitle: '',
//     price: 0,
//     period_per_pay: '',
//     tariff_type: []
// };
// const tariffTypeState = {
//     name: '',
//     type_name: "Интернет",
//     title: "до 100 Мбит/с",
//     subtitle: "Безлимитный интернет",
//     type: 1,
//     icon: '',
//     icon_path: '',
//     description: [],
// };
//
// function eventReducer(prevState, action) {
//     switch (action.type) {
//         case 'updateField':
//             return {
//                 ...prevState,
//                 [action.fieldName]: action.payload,
//             };
//         case 'addTypeInternet': {
//             const newEventType = { ...tariffTypeState, name: action.payload };
//
//             const updatedTariffTypes = [...prevState.tariff_type, newEventType];
//
//             return {
//                 ...prevState,
//                 tariff_type: updatedTariffTypes,
//             };
//         }
//         case 'updateType': {
//             const { eventType, fieldType } = action;
//             const updatedTariffTypes = [...prevState.tariff_type];
//             const indexToUpdate = updatedTariffTypes.findIndex((item) => item.type === fieldType);
//
//             if (indexToUpdate !== -1) {
//                 updatedTariffTypes[indexToUpdate] = eventType; // Заменяем объект на новый
//             }
//             return {
//                 ...prevState,
//                 tariff_type: updatedTariffTypes,
//             };
//         }
//         case 'removeTypeInternet':
//             return {
//                 ...prevState,
//                 tariff_type: prevState.tariff_type.filter((type) => type.type !== 1),
//             };
//         default:
//             return prevState;
//     }
// }
//
// function eventTypeReducer(prevState, action) {
//     switch (action.type) {
//         case 'updateField':
//             return {
//                 ...prevState,
//                 [action.fieldName]: action.payload,
//             };
//         default:
//             return prevState;
//     }
// }
//
// const [event, dispatch] = useReducer(eventReducer, initialState);
// const [eventType, dispatchType] = useReducer(eventTypeReducer, tariffTypeState);
// const handleChange = (e) => {
//     const {name, value} = e.target;
//     dispatch({type: 'updateField', fieldName: name, payload: value});
// };
// const handleChangeTypesValue = (e) => {
//     const { name, value } = e.target;
//     dispatchType({ type: 'updateField', fieldName: name, payload: value });
// };
// useEffect(() => {
//     dispatch({ type: "updateType", eventType, fieldType: 1 });
// }, [eventType]);
// function handlerTypeInternet(event, dispatch) {
//     const isTypeInternetAdded = event.tariff_type.some((item) => item.type === 1);
//     if (isTypeInternetAdded) {
//         dispatch({ type: "removeTypeInternet" });
//     } else {
//         dispatch({ type: "addTypeInternet" });
//     }
// }
// console.log(event)
// console.log(eventType)