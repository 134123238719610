import React, {useContext, useState} from 'react';
import './Header.css'
import logo from "../../shared/images/spectrum logo.svg"
import {Button, Container, IconButton, Stack, Typography} from "@mui/material";
import {BiChevronDown} from "react-icons/bi";
import {Link} from "react-router-dom";
import AuthSidebar from "../../entities/AuthSidebar";
import NotificationSidebar from "../../entities/Notification/NotificationSidebar";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import Avatar from "../../shared/svg/ReactIcon/Avatar";
import useMediaQuery from '@mui/material/useMediaQuery';
import HumberSidebar from "../../entities/HumberSidebar";
import MenuPopover from "../../features/MenuPopover";


const Header = observer(() => {
    const {store} = useContext(Context)
    const [openFilter, setOpenFilter] = useState(false);

    const handleOpenFilter = () => {
        setOpenFilter(true);
    };

    const handleCloseFilter = () => {
        setOpenFilter(false);
    };

    const isMobile = useMediaQuery('(max-width:1023px)');
    const isPad = useMediaQuery('(min-width:900px)');
    const isDesktop = useMediaQuery('(min-width:1024px)');
    return (
        <header className="header">
            <Container>
                <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    paddingY={"20px"}
                >

                    <Stack direction={"row"} gap={6}>
                        <Link to={'/'}>
                            <img src={logo}/>
                        </Link>
                        {isDesktop && <MenuPopover/>}
                    </Stack>
                    {
                        isMobile &&
                        <Stack direction={"row"} gap={1}>
                            {store.isAuth && <NotificationSidebar/>}
                            <HumberSidebar/>
                        </Stack>
                    }
                    {
                        isDesktop &&
                        <Stack direction={"row"} gap={6} alignItems={"center"}>
                            <nav>
                                <Stack direction={"row"} gap={4}>
                                    <Link to="/tariffs" underline="none">
                                        <Typography>
                                            Тарифы
                                        </Typography>
                                    </Link>
                                    <Link to="/news" underline="none">
                                        <Typography paragraph>
                                            Новости
                                        </Typography>
                                    </Link>
                                    <Link to="/contact" underline="none">
                                        <Typography paragraph>
                                            Контакты
                                        </Typography>
                                    </Link>
                                    {
                                        store.isAdmin &&
                                        <Link to="/admin" underline="none">
                                            <Typography>
                                                Админ панель
                                            </Typography>
                                        </Link>
                                    }
                                </Stack>
                            </nav>
                            {store.isAuth &&
                            <Stack direction={"row"} gap={1}>
                                <Link to="/profile" underline="none">
                                    <Button
                                        variant={"text"}
                                        olor={"secondary"}
                                        startIcon={<Avatar/>}
                                    >
                                        Константин К.
                                    </Button>
                                </Link>
                                <NotificationSidebar/>
                            </Stack>
                            }
                            {
                                !store.isAuth &&
                                <Button variant={"contained"} size={"medium"} href={'http://lk.spektr-tv.su/cabinet/welcome/'}>
                                    Личный кабинет
                                </Button>
                                // <AuthSidebar
                                //     openFilter={openFilter}
                                //     onOpenFilter={handleOpenFilter}
                                //     onCloseFilter={handleCloseFilter}
                                // />
                            }
                        </Stack>
                    }
                </Stack>
            </Container>
        </header>
    );
});

export default Header;