import React, {useContext, useEffect, useState} from 'react';
import {Container, Grid, Link, Typography} from "@mui/material";
import PageTitle from "../shared/layout/PageTitle";
import allEndpoints from "../shared/http";
import TariffList from "../Component/Tariff/TariffList";
import RouterSection from "../entities/RouterSection";
import {Context} from "../index";
import {observer} from "mobx-react-lite";

const Tariffs = observer(() => {
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [data, setData] = useState([]);
    const {store} = useContext(Context)
    const handleGetTariffs = async () => {
        setIsLoading(true);
        try {
            const response = await allEndpoints.tariff.getTariffs(store.cityOption.id)
            // Проверка HTTP-статуса ответа
            if (response.status === 200) {
                if(response.data.result){
                    setData(response.data.result)
                }
            } else {
                console.error('Ошибка при получении тарифов:', response.statusText);
                setIsError(true)
            }
        } catch (error) {
            // Обработка ошибки сети
            console.error('Ошибка сети при получении тарифов:', error.message);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        if (store.cityOption) {
            handleGetTariffs()
        }
    }, [store.cityOption])
    return (
        <Container>
            <PageTitle
                breadcrumbs={breadcrumbs}
                title={'Тарифы'}
                subtitle={'Ознакомьтесь с нашими тарифами и выберите оптимальное решение для себя. Выберите тариф и оставьте\n' +
                '                    заявку на подключение.'}
            />
            <Grid container spacing={3} mb={8}>
                <TariffList tariffs={data} isLoading={isLoading} isError={isError}/>
            </Grid>
            <RouterSection/>
        </Container>
    );
});

export default Tariffs;

const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
        Главная
    </Link>,
    <Link
        underline="hover"
        key="2"
        color="text.primary"
        href="/tariffs"

    >
        Тарифы
    </Link>
];