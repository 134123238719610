import React from 'react';
import './Footer.css'
import {Link} from "react-router-dom";
import {Button, Container, Divider, Stack, Typography} from "@mui/material";
import LogoFooter from "../../shared/svg/ReactIcon/LogoFooter";
import palette from "../../shared/theme/palette";
import useMediaQuery from "@mui/material/useMediaQuery";

const Footer = () => {
    const isMobile = useMediaQuery('(max-width:600px)');
    return (
        <footer>
            <section>
                <Container>
                    <Stack>
                        <Stack direction={"row"} pt={6} pb={5} justifyContent={"space-between"} alignItems={"center"}>
                            <Link to={'/'}>
                                <LogoFooter/>
                            </Link>
                            <a href={'https://vk.com/spektr_sktv'} style={{textDecoration: 'none'}}>
                                <Typography variant={'body2'} color={palette.grey["0"]}>Вконтакте</Typography>
                            </a>
                        </Stack>
                        <Divider color={"white"} sx={{height: 2, opacity: 0.2}}/>
                        <Stack
                            direction={isMobile ? "column" : "row"}
                            pt={isMobile ? 2 : 5}
                            color={palette.grey['400']}
                            pb={isMobile ? 4 : 13}
                            justifyContent={"space-between"}
                            alignItems={isMobile ? 'flex-start' : "center"}
                            spacing={isMobile && 10}
                        >
                            <Stack direction={isMobile ? "column" : "row"} gap={4}>
                                <Stack>
                                    <Typography variant={"h3"} color={palette.grey["0"]}>
                                        +7 913 391 08 08
                                    </Typography>
                                    <Typography variant={"secondaryMedium"}>Справочная</Typography>
                                </Stack>
                                <Stack>
                                    <Typography variant={"h3"} color={palette.grey["0"]}>
                                        +7 913 761 60 68
                                    </Typography>
                                    <Typography variant={"secondaryMedium"}>Справочная</Typography>
                                </Stack>
                            </Stack>
                            <Stack>
                                <Typography variant={"h3"} color={palette.grey["0"]}>spektr-sktv@mail.ru</Typography>
                                <Typography variant={"secondaryMedium"}>По всем вопросам</Typography>
                            </Stack>
                        </Stack>
                        <Divider color={"white"} sx={{height: 2, opacity: 0.2}}/>
                        <Stack
                            direction={isMobile ? 'column-reverse' : "row"}
                            color={palette.grey["400"]}
                            py={isMobile ? 2 : 5}
                            justifyContent={"space-between"}
                            alignItems={!isMobile && 'center'}
                            spacing={isMobile && 4}
                        >
                            <Typography variant={"secondaryMedium"}>© 2023, ООО СКТВ «Спектр»</Typography>
                            <Stack
                                direction={isMobile ? 'column' : "row"}
                                alignItems={isMobile && 'flex-start'}
                                spacing={isMobile ? 2 : 4}
                            >
                                <Link to={'doc'}>
                                    <Typography variant={'body2'} color={'inherit'}>Юридическая информация</Typography>
                                </Link>
                            </Stack>
                        </Stack>
                    </Stack>
                </Container>
            </section>
        </footer>
    );
};

export default Footer;